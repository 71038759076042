section.intro {
	background-color: var(--green);
	padding: 10rem 0;
	border-top-left-radius: var(--corner-radius);
	border-top-right-radius: var(--corner-radius);

	.section-title {
		color: var(--white);
	}
	.section-content {
		p {
			font-size: 1.8rem;
			padding: 2.6rem 0;
			line-height: 1.6;
			color: var(--white);
		}
	}
	// [class*="col-"] {
	//     display: flex;
	//     justify-content: center;
	//     flex-flow: column;
	// }
	@media screen and (orientation: portrait) {
		padding: 0 0 7rem 0;
		border-radius: 0;
		position: relative;

		&:before {
			position: absolute;
			content: " ";
			width: 100%;
			height: 7rem;
			top: -6.9rem;
			left: 0;
			border-top-left-radius: 5rem;
			border-top-right-radius: 5rem;
			background-color: var(--green);
		}

		.section-title {
			padding-bottom: 3rem;
		}
		.section-content {
			p {
				font-size: 1.5rem;
				padding: 1rem 0;
				line-height: 1.3;
			}
		}
	}
}
