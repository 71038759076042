$sm: 36em;
$md: 48em;
$lg: 62em;
$xl: 80em;
$xxl: 90em;
$hg: 100em;
$font-primary: "Mulish", sans-serif;
$color-name: #ff0000;
:root {
	--sal-duration: 1s;
	--primary-font: #{$font-primary};
	--name-color: #{$color-name};
	--header-height: 9.6rem;
	--header-height-mobile: 8rem;
	--header-radius: 4vw;
	--header-radius-mobile: 10rem;
	--corner-radius: 12rem;
	--corner-radius-mobile: 20rem;
	--black: #194034;
	--white: #fff;
	--green: #263f3d;
	--lime: #c9c73d;
	--teal: #01c5af;
	--pink: #f5b2ad;
	--burgundy: #a32638;
	--teal-accessible: #018374;
	--charfreuse: #c9c73d;
	--orange: #f55c40;
	--navy: #1c4f69;
	--purple-or-data: #342b67;
	--magenta-data: #a02a80;
	--gold-data: #f6bc32;
	--light-gold-data: #f9d18c;
	--cyan-data: #1ca3bb;
	--blue-data: #77d9fe;
	--lime-data: #7fb134;
	--light-lime-data: #c5ee92;
	--light-gray: #f7f7f7;
	--neutral-50: #f2f2f2;
	--plyr-color-main: #263f3d !important;
	--neutral-100: #f2f2f2;
	--gray: #a6a6a6;
	.animate__delay-0s {
		--animate-delay: 0.5s;
	}
}
