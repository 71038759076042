.featured-initial {
	border-bottom-left-radius: var(--corner-radius);
	.pic-caption {
		margin-left: 10rem;
	}
	@media screen and (orientation: portrait) {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: var(--corner-radius);

		.pic-caption {
			margin-left: 0rem;
		}
	}
}
.initial {
	padding-top: 10rem;
	background-color: var(--white);
	.section-title {
		display: flex;
		color: var(--teal-accessible);
		font-size: 7.2rem;
		font-weight: 800;
		padding-top: 2rem;
		.overline {
			text-transform: uppercase;
			color: var(--green);
			font-size: 2.4rem;
			font-weight: 500;
			padding-bottom: 2rem;
		}
		strong {
			color: var(--chafreuse);
		}
	}
	.section-content {
		position: relative;
		padding-bottom: 10rem;
		p {
			color: var(--green);
			font-size: 2rem;
			line-height: 1.4;
			padding: 2rem 0;
		}
	}
	@media screen and (orientation: portrait) {
		padding: 4rem 0 0 0;
		.section-title {
			font-size: 4rem;
			padding-bottom: 3.5rem;
		}
		.section-content {
			padding-bottom: 5rem;
			img {
				width: 100%;
				height: calc(100vw - 6rem);
				object-fit: cover;
			}
			&:before {
				right: -6rem;
				top: -3rem;
			}
			p {
				font-size: 1.5rem;
				line-height: 1.3;
				padding: 1.5rem 0;
			}
		}
	}
}
