.impact {
	.section-title {
		padding: 5rem 0;
	}
}

.slider-map {
	position: relative;
	.sub {
		padding: 3rem 0 1.5rem 0;
		color: var(--teal-accessible);
		display: flex;
		align-items: center;
		.number {
			font-size: 4.8rem;
			font-weight: 700;
			font-family: "Lora";
		}
		.text {
			font-size: 2rem;
			padding-left: 1.5rem;
		}
	}
	.arrows {
		position: absolute;
		width: 100vw;
		z-index: 10;
		top: 3rem;
		display: flex;
		justify-content: space-between;
		padding: 0 3rem;
		.arrow-prev {
			opacity: 0;
			transition: all 0.3s ease-in-out;
		}
		.arrow-next {
			opacity: 0;
			transition: all 0.3s ease-in-out;
		}
	}
	.horizontal-swiper.swiper {
		background-color: var(--white);
		color: var(--green);
		&:before {
			content: "";
			width: 100%;
			height: 5rem;
			position: absolute;
			bottom: 0;
			z-index: 49;
			background: linear-gradient(
				0deg,
				rgba(255, 255, 255, 1) 0%,
				rgba(255, 255, 255, 0) 100%
			);
		}
		> .swiper-button-prev {
			left: -3rem;
			opacity: 0;
		}
		> .swiper-button-next {
			right: -3rem;
			opacity: 0;
		}
		.section-title {
			padding-bottom: 4rem;
		}
		.first-slide {
			padding-left: 3rem;
		}
		.horizontal-slide {
			padding: 3rem;
			margin: auto;
			height: 48vh;
			overflow: scroll;
			overflow-x: hidden;
			margin-bottom: 1.7rem;
			&::-webkit-scrollbar-track {
				-webkit-box-shadow: none;
				background-color: rgba(0, 0, 0, 0.01);
			}
			&::-webkit-scrollbar {
				width: 1.5rem;
				background-color: rgba(0, 0, 0, 0.01);
			}
			&::-webkit-scrollbar-thumb {
				background-color: rgba(0, 0, 0, 0.1);
				border: none;
			}
			.section-video .video-wrapper {
				height: 22rem !important;
			}
		}
		.h5 {
			font-weight: 800;
		}
		.inner-content {
			padding-bottom: 3rem;
			a {
				text-decoration: underline;
			}
			p {
				font-size: 1.4rem;
				line-height: 1.5;
				padding-top: 1.5rem;
			}
			.icons {
				margin-top: 4rem;
				.each-icon {
					margin-bottom: 4rem;
					.heading {
						align-items: center;
						display: inline-flex;
						font-size: 2.4rem;
						font-weight: 700;
						text-transform: uppercase;
						img {
							border-radius: 100%;
							height: 8rem;
							margin-right: 2rem;
							width: 8rem;
						}
					}
					ul {
						margin-left: 4rem;
						li {
							font-size: 1.6rem;
							line-height: 1.6;
							list-style: disc;
						}
					}
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
		.swiper-outter {
			margin-left: -3vw;
			width: 91.5vw;
			// width: 97vw;
			// margin-left: -8vw;
		}
		.section-video,
		.section-video img {
			margin-left: -3vw;
			width: 91.5vw;
			// width: 97vw;
			// margin-left: -8vw;
			height: auto;
		}
	}
	.wrapper {
		position: relative;
		width: 100vw;
		height: 63.7vw;
		overflow: hidden;
		background-color: #cde9e3;
		.inner {
			overflow: hidden;
			transition: all 1s ease-in-out;
			.nation {
				position: relative;
				display: block;
				height: 63.7vw;
				width: 100vw;
				background-image: url(../images/2024/snapshots/map/bg-off.png);
				background-size: 100%;
				background-position: center center;
				img {
					position: absolute;
					left: 0;
					top: 0;
					right: 0;
					bottom: 0;
					width: 100%;
					height: 100%;
					display: block;
					object-fit: cover;
					opacity: 0;
					transition: all 1s ease-in-out;
				}
			}
			&.first {
				img {
					opacity: 1;
					transition: all 1.4s ease-in-out;
				}
			}
			&.poland {
				transform: scale(5) translateX(7.6rem) translateY(10rem);
				.img-poland {
					opacity: 1 !important;
				}
			}
			&.ukraine {
				transform: scale(4) translateX(4.2rem) translateY(9.5rem);
				.img-ukraine {
					opacity: 1 !important;
				}
			}
			&.afghanistan {
				transform: scale(4) translateX(-6.5rem) translateY(4rem);
				.img-afghanistan {
					opacity: 1 !important;
				}
			}

			&.drc {
				transform: scale(3) translateX(6.5rem) translateY(-7.5rem);
				.img-drc {
					opacity: 1 !important;
				}
			}
			&.iraq {
				transform: scale(4.2) translateX(0.5rem) translateY(4rem);
				.img-iraq {
					opacity: 1 !important;
				}
			}

			&.nigeria {
				transform: scale(4) translateX(10.5rem) translateY(-4rem);
				.img-nigeria {
					opacity: 1 !important;
				}
			}

			&.south-sudan {
				transform: scale(4) translateX(4.3rem) translateY(-4.2rem);
				.img-south-sudan {
					opacity: 1 !important;
				}
			}
			&.syria {
				transform: scale(4) translateX(2rem) translateY(4rem);
				.img-syria {
					opacity: 1 !important;
				}
			}

			&.myanmar {
				transform: scale(3.5) translateX(-15.7rem) translateY(-0.5rem);
				.img-myanmar {
					opacity: 1 !important;
				}
			}
			&.bangladesh {
				transform: scale(3.5) translateX(-13.7rem) translateY(0.5rem);
				.img-bangladesh {
					opacity: 1 !important;
				}
			}
			&.sudan {
				transform: scale(3) translateX(4rem) translateY(-2rem);
				.img-sudan {
					opacity: 1 !important;
				}
			}
			&.mali {
				transform: scale(3.8) translateX(14.5rem) translateY(-1.25rem);
				.img-mali {
					opacity: 1 !important;
				}
			}
			&.palestine {
				transform: scale(6) translateX(2.75rem) translateY(3.25rem);
				.img-palestine {
					opacity: 1 !important;
				}
			}
			&.burkina-faso {
				transform: scale(3.8) translateX(13.5rem) translateY(-3rem);
				.img-burkina-faso {
					opacity: 1 !important;
				}
			}
		}
	}
}

.interactive-map {
	position: relative;
	display: block;
	width: 100vw;
	height: calc(58.625vw - 19rem);
	overflow: hidden;
	color: var(--white);
	.section-title {
		bottom: 0;
		position: absolute;
		right: 35rem;
		text-align: right;
		transition: all 0.4s ease-in-out;
		width: 50rem;
		z-index: 9;
		padding: 0;
		svg {
			width: 100%;
			height: auto;
		}
	}
	&.zoom {
		.section-title {
			opacity: 0;
			transition: all 0.4s ease-in-out;
		}
		.content {
			display: block;
			.close {
				opacity: 1;
			}
		}
	}
	.content {
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 100;
		display: none;
		.each-map {
			background: red;
			left: 0%;
			position: absolute;
			top: 0;
			width: 43%;
			height: 100%;
			display: none;
			&.visible {
				display: block;
			}
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.each-content {
			background: white;
			right: 0%;
			position: absolute;
			top: 0;
			width: 57%;
			z-index: 99;
			height: 100%;
			padding: 8rem 28rem 8rem 15rem;
			display: none;
			color: var(--green);
			overflow-y: scroll;
			&.visible {
				display: block;
			}
			span.list {
				display: block;
				padding-left: 2rem;
			}
			.h5 {
				margin-bottom: 3rem;
				font-weight: 800;
				font-size: 4.8rem;
			}
			.inner-content p {
				font-size: 1.6rem;
				line-height: 1.6;
				margin: 2rem 0;
			}
			ul {
				margin-left: 4rem;
				li {
					font-size: 1.6rem;
					line-height: 1.6;
					list-style: disc;
				}
			}
			.icons {
				margin-top: 4rem;
				.each-icon {
					margin-bottom: 4rem;
					.heading {
						align-items: center;
						display: inline-flex;
						font-size: 2.4rem;
						font-weight: 700;
						text-transform: uppercase;
						img {
							border-radius: 100%;
							height: 8rem;
							margin-right: 2rem;
							width: 8rem;
						}
					}
					ul {
						margin-left: 4rem;
						li {
							font-size: 1.6rem;
							line-height: 1.6;
							list-style: disc;
						}
					}
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
			.quotes {
				padding-top: 6rem;
				.heading {
					align-items: center;
					display: inline-flex;
					font-size: 2.4rem;
					font-weight: 500;
				}
			}
			.partners {
				padding-top: 6rem;
				.heading {
					align-items: center;
					display: inline-flex;
					font-size: 2.4rem;
					font-weight: 500;
					padding-bottom: 2rem;
					display: block;
				}
				img {
					width: 18%;
					display: inline-block;
					margin-right: 2%;
				}
			}
			.gallery {
				padding-top: 6rem;
			}
			.image-wrapper,
			.video-wrapper {
				margin-top: 4rem;
				border-radius: 2rem;
				overflow: hidden;
				height: fit-content !important;
			}
			&::-webkit-scrollbar-track {
				-webkit-box-shadow: none;
				background-color: rgba(0, 0, 0, 0.01);
			}
			&::-webkit-scrollbar {
				width: 1.5rem;
				background-color: rgba(0, 0, 0, 0.01);
			}
			&::-webkit-scrollbar-thumb {
				background-color: rgba(0, 0, 0, 0.1);
				border: none;
			}
		}
		.close {
			// border: none;
			// cursor: pointer;
			// height: 6.4rem;
			// position: absolute;
			// left: 2rem;
			// bottom: 2rem;
			// width: 11rem;
			// z-index: 11;
			// height: 11rem;
			// background-color: transparent;
			// opacity: 0;
			// svg {
			// 	width: 11rem;
			// 	height: auto;
			// }
			position: absolute;
			top: 8rem;
			right: 12rem;
			width: 6.4rem;
			height: 6.4rem;
			cursor: pointer;
			border: none;
			background-color: var(--green);
			z-index: 9999;
			&:hover {
				--slash-deg: -180deg;
			}
			&::before,
			&::after {
				position: absolute;
				left: calc(50% - 2.4rem);
				content: "";
				width: 4.7rem;
				height: 0.3rem;
				background-color: var(--white);
				transform-origin: center center;
				transition: 500ms;
				border-radius: 1em;
			}
			&::before {
				transform: rotate(var(--slash-deg, -45deg));
			}
			&::after {
				transform: rotate(var(--slash-deg, 45deg));
			}
		}
	}
	.outter-wrapper {
		position: relative;
		display: block;
		width: 100vw;
		height: 58.625vw;
		transform: translateY(-19rem);
	}
	.wrapper {
		position: relative;
		display: block;
		width: 100vw;
		background-image: url(../images/bg-map-22.svg);
		background-size: 100%;
		background-position: center center;
		transition: all 1.4s ease-in-out;
		height: 58.625vw;
		background-position-x: 50%;
		.label {
			transition: all 0.5s ease-in-out;
		}
		&.highlight {
			transition: all 1s ease-in-out;
			.label {
				opacity: 0;
				cursor: pointer;
				transition: all 0.5s ease-in-out;
			}
			&-poland {
				transform: scale(3.4) translate(26.8rem, 35.4rem);
			}
			&-ukraine {
				transform: scale(2.8) translate(15.8rem, 33.4rem);
			}
			&-nigeria {
				transform: scale(3.4) translate(39.8rem, -22.4rem);
			}
			&-drc {
				transform: scale(3) translate(22rem, -35rem);
			}

			&-south-sudan {
				transform: scale(3.2) translate(17rem, -21rem);
			}

			&-syria {
				transform: scale(3.4) translate(9rem, 10rem);
			}
			&-iraq {
				transform: scale(3.4) translate(3rem, 8rem);
			}
			&-afghanistan {
				transform: scale(3.4) translate(-28rem, 9rem);
			}
			&-myanmar {
				transform: scale(3.4) translate(-58rem, -9rem);
			}
			&-bangladesh {
				transform: scale(3.4) translate(-58rem, -9rem);
			}
			&-mali {
				transform: scale(3) translate(63.8rem, -12.4rem);
			}
			&-burkina-faso {
				transform: scale(3) translate(59.8rem, -16.4rem);
			}
			&-sudan {
				transform: scale(3) translate(23.8rem, -16.4rem);
			}
			&-palestine {
				transform: scale(4.5) translate(19.8rem, 3.6rem);
			}
		}
	}
	.nation {
		position: absolute;
		transition: all 0.5s ease-in-out;
		&.highlight {
			z-index: 9;
		}
		&.hidden svg path {
			fill: #a4cbc5;
			stroke: #a4cbc5;
			transition: all 0.5s ease-in-out;
		}
		path,
		rect {
			cursor: pointer;
			display: block;
		}
		&.poland {
			left: 39.4rem;
			top: 21.8rem;
			svg {
				width: 25.5rem;
			}
		}
		&.ukraine {
			left: 62.6rem;
			top: 25.9rem;
			svg {
				width: 35.5rem;
			}
		}
		&.nigeria {
			left: 39.3rem;
			top: 69.6rem;
			z-index: 11;
			svg {
				width: 14.9rem;
			}
		}
		&.drc {
			left: 51.2rem;
			top: 87.3rem;
			svg {
				width: 21.4rem;
			}
		}

		&.south-sudan {
			left: 53.6rem;
			top: 79.2rem;
			svg {
				width: 24.3rem;
			}
		}

		&.syria {
			left: 65.7rem;
			top: 47.7rem;
			svg {
				width: 20.51rem;
			}
		}
		&.iraq {
			left: 81.7rem;
			top: 40.1rem;
			svg {
				width: 11.3rem;
			}
		}
		&.afghanistan {
			left: 107.2rem;
			top: 46.5rem;
			svg {
				width: 16.8rem;
			}
		}
		&.myanmar {
			left: 140.4rem;
			top: 53.9rem;
			svg {
				width: 12.6rem;
			}
		}
		&.bangladesh {
			left: 120.1rem;
			top: 62.2rem;
			svg {
				width: 23.8rem;
			}
		}
		&.burkina-faso {
			left: 27.6rem;
			top: 75.9rem;
			z-index: 11;
			svg {
				width: 12.7rem;
			}
		}
		&.mali {
			left: 22.8rem;
			top: 56.35rem;
			z-index: 10;
			svg {
				width: 19.55rem;
			}
		}
		&.sudan {
			left: 53.35rem;
			top: 65.8rem;
			svg {
				width: 27.9rem;
			}
		}
		&.palestine {
			left: 72rem;
			top: 54rem;
			svg {
				width: 11.51rem;
			}
		}
	}
}
