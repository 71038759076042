.header {
	position: fixed;
	width: 96vw;
	height: var(--header-height);
	top: 0;
	left: 0;
	background-color: var(--white);
	z-index: 80;
	margin: 3rem 2vw;
	border-radius: var(--header-radius);
	transition: all 0.5s ease-in-out;
	.header-wrapper {
		display: flex;
		align-items: center;
		height: 100%;
	}
	#full-menu {
		display: flex;
		width: 100%;
		justify-content: flex-end;
		position: relative;
		overflow: hidden;
		height: 0;
		transform: translateY(-5rem);
		transition: all 0.5s ease-in-out;
		.container {
			max-width: 85vw;
		}
		&.open {
			height: 45rem;
		}
	}
	&:before {
		content: " ";
		background: var(--white);
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		position: absolute;
		border-radius: 10rem;
		z-index: 81;
	}
	&.hidden {
		top: calc(var(--header-height) * -1.5);
		transition: all 0.5s ease-in-out;
	}
	.container #branding {
		z-index: 82;
		position: relative;
		img {
			width: 30rem;
			z-index: 81;
		}
	}
	a.nav-handler {
		position: relative;
		font-size: 1.8rem;
		width: 100%;
		padding: 0.75rem 6rem 0.5rem 3rem;
		color: var(--black);
		transition: all 0.5s ease-in-out;
		z-index: 82;
	}
	a.btn {
		background-color: var(--pink);
		text-transform: uppercase;
		color: var(--burgundy);
		padding: 0.3rem 2.2rem;
		border-radius: 5rem;
		font-size: 1.6rem;
		font-weight: bold;
		margin-top: 0.2rem;
		z-index: 81;
		position: relative;
		&:hover {
			background-color: var(--teal);
			color: var(--green);
		}
	}
	.menu-trigger {
		display: inline-flex;
		width: 100%;
		justify-content: end;
		align-items: center;
		height: 4.5rem;
		.menu-toggle {
			width: fit-content;
			height: 3.2rem;
			position: relative;
			transition-duration: 1s;
			display: flex;
			z-index: 99;
			cursor: pointer;
			.icon {
				width: 4rem;
				height: 0.25rem;
				top: 1.75rem;
				right: 0;
				background-color: #194034;
				position: absolute;
				transition-duration: 0.5s;
				&:before {
					left: 0;
					position: absolute;
					top: -0.75rem;
					height: 0.25rem;
					width: 4rem;
					background-color: #194034;
					content: "";
					transition-duration: 0.5s;
				}
				&:after {
					left: 0;
					position: absolute;
					top: 0.75rem;
					height: 0.25rem;
					width: 4rem;
					background-color: #194034;
					content: "";
					transition-duration: 0.5s;
				}
			}
			.icon {
				transition-duration: 0.5s;
				transition-delay: 0.5s;
				&:after {
					transition:
						transform 0.5s,
						top 0.5s 0.5s;
				}
				&:before {
					transition:
						transform 0.5s,
						top 0.5s 0.5s;
				}
			}
		}
		.menu-toggle.open .icon {
			transition-duration: 0.1s;
			transition-delay: 0.5s;
			background: transparent;
		}
		.menu-toggle.open .icon:before {
			transition:
				top 0.5s,
				transform 0.5s 0.5s;
			top: 0px;
			transform: rotateZ(-45deg);
		}
		.menu-toggle.open .icon:after {
			transition:
				top 0.4s,
				transform 0.5s 0.5s;
			top: 0px;
			transform: rotateZ(45deg);
		}
	}
	#menu {
		display: block;
		position: absolute;
		background-color: var(--white);
		top: 0;
		border-radius: var(--header-radius);
		width: 100%;
		left: 0;
		transform: translateY(-100%);
		padding: var(--header-height) 6rem 3rem 6rem;
		transition: all 0.5s ease-in-out;
		z-index: 79;
		ul {
			transition: all 0.75s ease-in-out;
			opacity: 0;
			columns: 3;
			padding: 6rem 0 7rem 0;
			li {
				margin: 0 0 2rem 0;
			}
			a {
				font-size: 3.2rem;
				display: block;
				padding: 1rem;
				transition: all 0.5s ease-in-out;
				&:hover {
					background-color: var(--teal-accessible);
					font-weight: 800;
					transition: all 0.5s ease-in-out;
				}
			}
		}
		&.active {
			transform: translateY(-5rem);
			transition: all 0.5s ease-in-out;
			ul {
				transition: all 0.75s ease-in-out;
				opacity: 1;
			}
		}
	}
}

.banner {
	width: 100%;
	height: 100vh;
	position: relative;
	display: flex;
	align-items: center;
	color: var(--white);
	z-index: -1;
	// .pic-caption {
	//     top: calc(calc(100vh - var(--header-height)) - 7.5rem);
	//     left: 11.5rem;
	//     bottom: auto;
	//     z-index: 2;
	// }
	.container {
		z-index: 1;
	}
	img.she-dares {
		width: 46rem;
		height: auto;
	}
	.wrapper,
	.image-wrapper,
	.image-wrapper img {
		position: absolute;
		display: block;
		width: 100vw;
		height: calc(100vh + 10rem);
		overflow: hidden;
		background-color: black;
		object-fit: cover;
	}
	.player-wrapper {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		width: 100vw;
		height: calc(100vh + 10rem);
		object-fit: cover;
		opacity: 0.8;
		.plyr {
			height: 100%;
			video {
				width: 100vw;
				object-fit: cover;
			}
			.plyr__poster {
				background-size: cover;
			}
		}
	}
	.swiper-slide {
		background-color: var(--black);
	}
	// img {
	//     display: block;
	//     width: 100vw;
	//     height: 100vh;
	//     object-fit: cover;
	//     /* or object-fit: contain; */
	//     z-index: -1;
	//     opacity: 0.7;
	// }
	.bg {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		top: 0;
		background-size: cover;
		z-index: -1;
	}
	.arrow-down {
		animation: bounce-arrow 2s infinite;
		svg {
			width: 7rem;
		}
		cursor: pointer;
	}
	.subtitle {
		margin: 3rem 0 4rem 0;
	}
}

@keyframes bounce-arrow {
	0%,
	20%,
	50%,
	80%,
	100% {
		transform: translateY(0);
	}
	40% {
		transform: translateY(0.5rem);
	}
	60% {
		transform: translateY(1rem);
	}
}

@keyframes bounce-arrow-right {
	0%,
	20%,
	50%,
	80%,
	100% {
		transform: translateX(0);
	}
	40% {
		transform: translateX(0.5rem);
	}
	60% {
		transform: translateX(1rem);
	}
}

//Mobile
@media screen and (orientation: portrait) {
	#progress-bar {
		display: none;
	}
	.header {
		height: var(--header-height-mobile);
		border-radius: var(--header-radius-mobile);
		.container #branding {
			img {
				width: 21rem;
			}
		}
		#full-menu {
			position: fixed;
			top: var(--header-height);
			bottom: 0;
			right: -100vw;
			#menu {
				transform: none;
			}
		}
		#full-menu {
			position: fixed;
			width: 100vw;
			height: 100vh;
			display: flex;
			flex-flow: column;
			background-color: var(--white);
			top: 0;
			justify-content: flex-start;
			padding: 13rem 3rem 3rem 3rem;
			overflow-y: scroll;
			transition: all 0.5s ease-in-out;
			border-bottom-left-radius: 16rem;
			.container {
				max-width: 100%;
				padding: 0;
			}
			#menu {
				position: relative;
				width: 100%;
				padding: 0;
				top: 3rem;
				height: fit-content;
				ul {
					column-count: 1;
					padding: 6rem 0 1.5rem;
					li {
						margin-bottom: 0.7rem;
					}
					a {
						font-size: 2.4rem;
					}
				}
			}
			.d-flex {
				flex-flow: column;
			}
			&.open {
				right: 0;
				transition: all 0.5s ease-in-out;
				height: 100vh;
			}
		}
	}
	.banner {
		height: 95vh;
		align-items: flex-end;
		.arrow-down {
			animation: bounce-arrow 2s infinite;
			padding-bottom: 12rem;
		}
		.subtitle {
			margin: 1.25rem 0 4rem 0;
			font-size: 2.4rem;
		}
		.wrapper {
			height: 95vh;
		}
		// .player-wrapper {
		// 	height: 90vh;
		// 	object-fit: cover;
		// 	width: 100%;
		// 	video {
		// 		width: 100%;
		// 		height: 100%;
		// 		object-fit: cover;
		// 	}
		// }
		img.she-dares {
			width: 27rem;
		}
	}
	.menu-trigger {
		position: absolute;
		right: 3rem;
		top: 1.5rem;
	}
}
