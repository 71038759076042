.section-banner {
	// padding-bottom: 10rem;
	.wrapper {
		display: block;
		position: relative;
		width: 100vw;
		height: 59rem;
		overflow: hidden;
	}
	img.desktop {
		display: block;
		width: 100vw;
		height: 64rem;
		object-fit: cover;
		object-position: center;
	}
	@media screen and (orientation: portrait) {
		.wrapper {
			width: 100vw;
			height: 100vw;
		}
		img.mobile {
			display: block;
			width: 100vw;
			height: 100vw;
			object-fit: cover;
			object-position: center;
		}
	}
}

.section-video-parallax {
	width: 100%;
	height: 47rem;
	overflow: hidden;
	@media screen and (orientation: portrait) {
		height: 100vw;
	}
	.video-wrapper,
	video,
	.video-js,
	.vjs-text-track-display {
		@media screen and (orientation: portrait) {
			height: 100vw;
		}
		height: 47rem;
		width: auto;
		overflow: visible;
		padding: 0;
	}
	.vjs-poster {
		height: 47rem;
		background-size: cover;
	}
	iframe {
		max-height: 75rem !important;
	}
	img {
		width: 100%;
		height: 67rem;
		object-fit: cover;
		object-position: center center;
		@media screen and (orientation: portrait) {
			height: 130vw;
			width: 100vw;
		}
	}
}

.section-video {
	width: 100%;
	height: 47rem;
	@media screen and (orientation: portrait) {
		height: 100vw;
	}
	.video-wrapper,
	video,
	.video-js,
	.vjs-text-track-display {
		@media screen and (orientation: portrait) {
			height: 100vw;
		}
		height: 47rem;
		width: auto;
		overflow: hidden;
		padding: 0;
	}
	.vjs-poster {
		height: 47rem;
		background-size: cover;
	}
	iframe {
		max-height: 75rem !important;
	}
	img {
		width: 100%;
		height: auto;
		object-fit: cover;
		object-position: center center;
		@media screen and (orientation: portrait) {
			height: 130vw;
			width: auto;
		}
	}
}

.image-wrapper {
	position: relative;
	img {
		width: 100%;
	}
}

.video-js {
	.vjs-big-play-button {
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		border-radius: 100%;
		height: 10rem;
		width: 10rem;
		background: transparent;
		font-size: 6rem;
	}
}

.pic-caption {
	font-size: 1.6rem;
	font-weight: 500;
	font-style: italic;
	position: absolute;
	bottom: 1.5rem;
	left: 1.5rem;
	color: var(--white);
	max-width: 90%;
	a {
		text-decoration: underline;
		color: var(--white);
		&:hover {
			text-decoration: none;
		}
	}
}

a.learn-more {
	position: relative;
	font-size: 1.8rem;
	color: var(--green);
	text-decoration: none;
	font-weight: normal;
	&:after {
		content: "";
		position: absolute;
		width: 0;
		height: 0.2rem;
		background: var(--green);
		left: 0;
		bottom: -0.2rem;
		transition: all 0.5s ease-in-out;
	}
	&:before {
		content: "";
		position: absolute;
		width: 4.7rem;
		height: 2.8rem;
		right: -4.5rem;
		bottom: -0.2rem;
		background-image: url("../images/arrow-right.svg");
		background-size: contain;
		background-position: right center;
		background-repeat: no-repeat;
		transition: all 0.5s ease-in-out;
	}
	&:hover {
		&:after {
			width: 100%;
			transition: all 0.5s ease-in-out;
		}
		&:before {
			right: -4.7rem;
			transition: all 0.5s ease-in-out;
		}
	}
}

div#wrapper {
	.swiper-horizontal .swiper-scrollbar {
		height: 1.7rem !important;
		width: 100% !important;
		background-color: var(--teal);
		border-radius: 0 !important;
		bottom: 0 !important;
		left: 0 !important;
	}
	.swiper-scrollbar-drag {
		background-color: var(--teal-accessible);
		position: relative !important;
		border-radius: 0 !important;
		width: calc(100vw + 30px) !important;
		left: -100vw !important;
	}
	// .swiper-scrollbar-progress {
	//     background-color: var(--teal-accessible);
	//     position: absolute;
	//     top: 0;
	//     left: -100vw;
	//     height: 1.7rem;
	//     width: 100vw;
	//     transform: translateX(30px);
	//     display: none;
	// }
}

a.underline {
	text-decoration: underline;
	&:hover {
		text-decoration: none;
	}
}

span.underline {
	text-decoration: underline;
	&:hover {
		text-decoration: none;
	}
}

a.bold {
	font-weight: bold;
}

a.glightbox {
	&:before {
		content: "";
		height: 10.5rem;
		position: absolute;
		width: 10.8rem;
		z-index: 2;
		left: calc(50% - 5.4rem);
		top: calc(50% - 5.25rem);
		border-radius: 100%;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		background-image: url("../images/icon-play.svg");
		transition: all 0.5s ease-in-out;
	}
	&:hover {
		&:before {
			background-color: rgba(0, 0, 0, 0.2);
			transition: all 0.5s ease-in-out;
		}
	}
	display: block;
	width: 100%;
	height: 100%;
	position: relative;
}

.goverlay {
	background: rgba(38, 63, 61, 0.9) !important;
}

.gclose.gbtn {
	svg {
		display: none;
	}
	position: absolute;
	top: 0rem;
	right: 0;
	width: 6.4rem;
	height: 6.4rem;
	border-radius: 0;
	cursor: pointer;
	border: none;
	z-index: 99;
	&:hover {
		--slash-deg: -180deg;
	}
	&::before,
	&::after {
		position: absolute;
		left: calc(50% - 2.4rem);
		content: "";
		width: 4.7rem;
		height: 0.3rem;
		background-color: var(--white);
		transform-origin: center center;
		transition: 500ms;
		border-radius: 1em;
	}
	&::before {
		transform: rotate(var(--slash-deg, -45deg));
	}
	&::after {
		transform: rotate(var(--slash-deg, 45deg));
	}
}

.gprev.gbtn,
.gnext.gbtn {
	display: none;
}

.glightbox-clean .gslide-media {
	box-shadow: none !important;
}
