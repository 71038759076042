section.global-findings {
	background-color: var(--pink);
	padding: 12rem 0;
	position: relative;
	&::before {
		content: " ";
		position: absolute;
		width: 100%;
		height: 15rem;
		top: -15rem;
		z-index: -1;
		background-color: var(--pink);
	}

	.section-title {
		color: var(--burgundy);
		font-size: 7.2rem;
		font-weight: 800;
	}
	.section-content {
		p {
			font-size: 2rem;
			padding: 3.6rem 0 3rem 0;
			line-height: 1.6;
			color: var(--green);
		}
		.row {
			padding-bottom: 8rem;
			border-bottom: 0.3rem solid var(--burgundy);
		}
	}
	.section-findings {
		padding-top: 10rem;
		ul {
			position: relative;
			li {
				position: relative;
				border-radius: 10rem;
				width: 100%;
				padding: 1rem;
				margin-bottom: 2rem;
				transition: all 0.3s ease-in-out;
				cursor: pointer;
				a {
					display: flex;
					width: 100%;
					align-items: center;
					font-size: 2.8rem;
					color: var(--green);
					transition: all 0.3s ease-in-out;
					img {
						width: 6.4rem;
						height: 6.4rem;
						border-radius: 100%;
						object-fit: cover;
						margin-right: 2rem;
						position: relative;
					}
					&::before {
						position: absolute;
						content: "";
						background-color: var(--pink);
						width: 6.4rem;
						height: 6.4rem;
						border-radius: 100%;
					}
				}
				&.active {
					transition: all 0.3s ease-in-out;
					background-color: var(--burgundy);
					a {
						transition: all 0.3s ease-in-out;
						color: var(--pink);
					}
					&:after {
						background-image: url(../images/findings-arrow-right.svg);
						content: " ";
						height: 4rem;
						position: absolute;
						right: 1rem;
						width: 4rem;
						top: 2.2rem;
						background-size: contain;
					}
					img {
						filter: brightness(0) saturate(100%) invert(21%)
							sepia(17%) saturate(841%) hue-rotate(126deg)
							brightness(91%) contrast(92%);
					}
				}
				&:hover {
					transition: all 0.3s ease-in-out;
					background-color: var(--burgundy);
					a {
						transition: all 0.3s ease-in-out;
						color: var(--pink);
					}
					img {
						filter: brightness(0) saturate(100%) invert(21%)
							sepia(17%) saturate(841%) hue-rotate(126deg)
							brightness(91%) contrast(92%);
					}
				}
			}
		}
		.each-finding {
			display: none;
			&.active {
				display: block;
			}
			.finding-title {
				display: inline-flex;
				align-items: center;
				font-size: 4.8rem;
				font-weight: 800;
				color: var(--burgundy);
				margin-bottom: 4rem;
				img {
					width: 12rem;
					height: 12rem;
					border-radius: 100%;
					margin-right: 2rem;
				}
			}
			p {
				font-size: 1.8rem;
				line-height: 1.4;
				margin-bottom: 3rem;
			}
		}
		.accordion-container {
			.ac {
				background: transparent;
				border: none;
				border-radius: 2rem;
				padding: 1.5rem;
				font-family: var(--primary-font) !important;
				line-height: 1.6;
				position: relative;
				&:before {
					position: absolute;
					content: "";
					width: 100%;
					height: 0.2rem;
					background-color: var(--burgundy);
					left: 0;
					top: -0.4rem;
				}
				&.is-active {
					background-color: var(--burgundy);
					color: var(--light-gray);
					font-size: 1.8rem;
					font-weight: 500;
					.heading {
						color: var(--pink);
					}
					&::before {
						height: 0;
					}
					.ac-trigger::after {
						transition: transform 0.5s ease-in-out;
						content: " ";
						transform: rotate(180deg) translateY(1rem);
						filter: brightness(0) saturate(100%) invert(100%)
							sepia(67%) saturate(3179%) hue-rotate(291deg)
							brightness(98%) contrast(96%);
					}
				}
				.ac-trigger {
					font-family: var(--primary-font);
					color: var(--burgundy);
					transition: all 0.5s ease-in-out;
					&:focus {
						color: var(--burgundy);
					}
					&:after {
						transition: transform 0.5s ease-in-out;
						content: " ";
						background-image: url("../images/arrow-down-fd.svg");
						width: 2.4rem;
						height: 2.4rem;
					}
				}
				&.next {
					transition: all 0.5s ease-in-out;
					&::before {
						height: 0;
					}
				}
				p {
					margin: 1.5rem 0 1.5rem 0;
					line-height: 1.6;
					font-size: 1.4rem;
					&:last-child {
						margin-bottom: 0.5rem;
					}
				}
				.ac-header {
					.heading {
						display: flex;
						align-items: center;
						font-family: var(--primary-font) !important;
					}
					img {
						border-radius: 100%;
						height: 5.3rem;
						margin-right: 2rem;
						width: 5.3rem;
						background-color: var(--pink);
						padding: 0.5rem;
					}
				}
			}
		}
	}

	@media screen and (orientation: portrait) {
		padding: 7rem 0;
		.section-title {
			padding-bottom: 4rem;
			font-size: 4.8rem;
		}
		.section-content {
			.row {
				padding-bottom: 0;
				border: none;
			}
			p {
				font-size: 1.4rem;
				padding: 1rem 0;
				line-height: 1.6;
			}
		}
		.section-findings {
			padding-top: 3rem;
		}
	}
}
