@import "bootstrap";
@import "fonts";
@import "reset";
@import "variables";
@import "utils/easing";
@import "utils/helpers";
@import "base";
@import "layout/general";
@import "layout/footer";
@import "layout/00-progress";
@import "layout/00-swiper";
@import "layout/01-header";
@import "layout/02-intro";
@import "layout/03-initial";
@import "layout/04-icons";
@import "layout/05-global-findings";
@import "layout/06-map";
@import "layout/07-process";
@import "layout/08-contributors";
@import "layout/08-modal-contributors";
@import "layout/09-recommendations";
@import "layout/10-next";
