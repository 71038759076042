.swiper-outter {
    --swiper-navigation-color: var(--green);
    --swiper-theme-color: var(--green);
    --swiper-pagination-bullet-horizontal-gap: 1.5rem;
    .swiper {
        .image-wrapper {
            border-radius: 2rem;
            overflow: hidden;
        }
    }
    .swiper-nav-wrapper {
        position: relative;
        width: 100%;
        height: 7rem;
        display: flex;
        align-items: center;
        justify-content: center;
        .swiper-pagination {
            position: relative;
            width: fit-content;
            top: 2.5rem;
        }
        .swiper-button-next,
        .swiper-button-prev {
            position: relative;
            left: auto;
            right: auto;
            bottom: 0;
            &:after {
                font-size: 3rem;
            }
        }
        .swiper-button-prev {
            order: 0;
            padding-right: 2rem;
        }
        .swiper-pagination {
            order: 1;
        }
        .swiper-button-next {
            order: 2;
            padding-left: 2rem;
        }
        span.swiper-pagination-bullet {
            width: 1.5rem;
            height: 1.5rem;
        }
    }
    @media screen and (orientation: portrait) {
        .swiper-nav-wrapper {
            transform: scale(0.75);
        }
    }
}
