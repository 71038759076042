section.icons {
	background-color: var(--neutral-50);
	color: var(--green);
	border-bottom-right-radius: var(--corner-radius);
	.section-image {
		img {
			transform: translateX(-11rem);
			width: calc(11rem + 100%);
			object-fit: cover;
			height: auto;
		}
	}
	.section-title {
		position: relative;
		display: flex;
		align-items: center;
		font-size: 7.2rem;
		font-weight: 800;
		margin-top: 15rem;
	}
	.section-icons {
		padding-top: 5rem;
		.each-col {
			display: flex;
			flex-flow: row;
			justify-content: flex-start;
			padding: 2rem 0;

			img.gif {
				opacity: 1;
			}
			img {
				width: 12rem;
			}
			.content {
				padding-left: 1.5rem;
				display: flex;
				flex-flow: column;
				justify-content: center;

				.top {
					display: flex;
					align-items: center;
					.title {
						font-size: 3.2rem;
						font-weight: 700;
						padding-left: 1.5rem;
					}
				}
				.bottom {
					.caption {
						text-align: left;
						font-size: 1.8rem;
						margin: 0.5rem 0;
					}
				}

				.number {
					display: inline-flex;
					font-family: "Lora";
					font-size: 8rem;
				}
			}
		}
	}
	@media screen and (orientation: portrait) {
		padding: 0 0 5rem 0;
		border-bottom-right-radius: 5rem;
		.section-title {
			margin-top: 6rem;

			padding: 2rem 0 3rem 0;
			font-size: 4.8rem;
			// &:before {
			// 	height: calc(100% + 5rem);
			// 	left: -15vw;
			// 	top: -5rem;
			// 	width: 110vw;
			// 	border-top-right-radius: var(--corner-radius);
			// }
			// &:after {
			// 	height: 5rem;
			// 	left: 3rem;
			// 	top: calc(100% - 4rem);
			// 	transform: rotate(45deg);
			// 	width: 5rem;
			// }
		}
		.section-image {
			img {
				transform: translateX(-3.25rem);
				width: calc(100vw + 0.5rem);
				height: 100vw;
			}
		}
		.caption {
			margin-top: 4rem;
			p {
				font-size: 1.4rem;
				br {
					display: block;
				}
			}
		}
		.section-icons {
			padding-left: 0;
			padding-top: 0rem;
			.each-col {
				flex-flow: row;
				margin-top: 2rem;
				justify-content: flex-start;
				.icon {
					display: flex;
				}
				img,
				canvas {
					width: 8rem;
				}

				.content {
					padding-left: 3rem;
					.top {
						flex-flow: column;
						align-items: flex-start;
						.title {
							width: 100%;
							font-size: 2.4rem;
							margin-top: 0rem;
							padding-left: 0;
						}
					}
					.bottom {
						.caption {
							font-size: 1.4rem;
						}
					}

					.number {
						font-size: 4.8rem;
						min-width: 16rem;
					}
				}
			}
		}
		@media screen and (min-width: 576px) {
			.each-col {
				margin-left: 0 !important;
				img,
				canvas {
					width: 10rem;
					padding: 0rem;
					&.small {
						width: 8.8666rem;
						padding-left: 5rem;
					}
				}
				.left.small canvas {
					width: 9.8666rem;
				}
				.title {
					width: 12rem;
					font-size: 1.5rem;
					margin-top: 1.4rem;
				}
				.number {
					font-size: 3rem;
					min-width: 11rem;
				}
			}
		}
	}
}
