.pre-footer {
	text-align: right;
	padding: 0;
	position: relative;
	.middle {
		text-align: left;
	}
	.col-md-6 {
		padding: 9rem 0;
		position: relative;
		&.left {
			color: var(--white);
			background-color: var(--teal);
			&:before {
				width: calc(100% + 5vw);
				height: 100%;
				content: " ";
				position: absolute;
				left: -5vw;
				top: 0;
				background-color: var(--teal);
				z-index: -1;
			}
		}
		&.right {
			background-color: var(--green);
			&:before {
				width: calc(100% + 5vw);
				height: 100%;
				content: " ";
				position: absolute;
				left: 0;
				top: 0;
				background-color: var(--green);
				z-index: -1;
			}
		}
	}
	.supporters {
		display: inline-flex;
		align-items: center;
		text-align: left;
		color: var(--black);
		.img {
			display: flex;
		}
		img {
			width: 16rem;
			margin-right: 1rem;
		}
		.text {
			width: 27rem;
			font-weight: bold;
		}
	}
	.find-out {
		display: inline-flex;
		align-items: center;
		.text {
			color: var(--white);
		}
		svg {
			width: 12.8rem;
			margin-left: 4rem;
		}
	}

	@media screen and (orientation: portrait) {
		padding: 3rem 0 0 0;
		text-align: center;
		&:before {
			transform: rotate(90deg);
		}
		.col-md-6 {
			padding: 2rem 0;
			height: 16rem;
			position: relative;
			.fs-40 {
				font-size: 2.4rem;
			}
			&.left {
				&:before {
					height: 100%;
					left: -2.25rem;
					width: 100vw;
				}
			}
			&.right {
				&:before {
					height: 100%;
					left: -2.25rem;
					width: 100vw;
				}
			}
		}

		.left {
			flex-flow: column !important;
		}
		.supporters {
			display: inline-flex;
			align-items: center;
			text-align: center;
			color: var(--black);
			flex-flow: column;
			img {
				width: 12rem;
				margin-right: 1rem;
			}
			.text {
				width: 23rem;
				text-align: center;
				padding-top: 1rem;
				font-weight: bold;
			}
		}
		.find-out {
			display: inline-flex;
			align-items: center;
			.text {
				color: var(--white);
			}
			svg {
				width: 12.8rem;
				margin-right: 3rem;
				margin-left: 1rem;
			}
		}
	}
}

.footer {
	background-color: var(--teal-accessible);
	padding: 7rem 0 5rem;
	font-size: 1.4rem;
	color: var(--white);
	line-height: 1.2;
	.logo {
		width: 47rem;
	}
	#copyright {
		margin-top: 5rem;
	}
	ul.socials {
		display: flex;
		justify-content: flex-end;
		li {
			margin-left: 1rem;
			border-radius: 100%;
			transition: all 0.5s ease-in-out;
			cursor: pointer;
			&:hover {
				background-color: var(--teal);
				transition: all 0.5s ease-in-out;
			}
			a {
				display: block;
			}
			img {
				width: 6rem;
			}
		}
	}
	@media screen and (orientation: portrait) {
		text-align: center;
		img.logo {
			width: 100%;
		}
		ul.socials {
			justify-content: center;
			margin: 2rem 0 5rem;
		}
	}
}
