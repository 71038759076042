html.has-modal-open {
	overflow: hidden !important;
}

.modal-programs {
	position: fixed;
	color: var(--white);
	width: 100vw;
	height: 100vh;
	min-height: 100vh;
	z-index: 111;
	top: 0;
	left: 0;
	display: none;
	overflow: hidden;
	.dark {
		color: var(--green) !important;
		a,
		p,
		p a {
			color: var(--green) !important;
		}
	}
	.container-fluid {
		height: 100%;
	}
	a.learn-more {
		filter: brightness(0) invert(1);
	}
	&.prog-1 {
		.prog-1 {
			display: block;
		}
		.nav-2 {
			display: flex;
		}
		.close {
			background-color: var(--teal);
		}
	}
	&.prog-2 {
		.prog-2 {
			display: block;
		}
		.nav-3 {
			display: flex;
		}
		.close {
			background-color: var(--teal);
		}
	}
	&.prog-3 {
		.prog-3 {
			display: block;
		}
		.nav-4 {
			display: flex;
		}
		.close {
			background-color: var(--teal);
		}
	}
	&.prog-4 {
		.prog-4 {
			display: block;
		}
		.nav-5 {
			display: flex;
		}
		.close {
			background-color: var(--teal);
		}
	}
	&.prog-5 {
		.prog-5 {
			display: block;
		}
		.nav-6 {
			display: flex;
		}
		.close {
			background-color: var(--teal);
		}
	}
	&.prog-6 {
		.prog-6 {
			display: block;
		}
		.nav-1 {
			display: flex;
		}
		.close {
			background-color: var(--teal);
		}
	}
	.close {
		position: absolute;
		top: 0rem;
		right: 0;
		width: 6.4rem;
		height: 6.4rem;
		cursor: pointer;
		border: none;
		z-index: 99;
		&:hover {
			--slash-deg: -180deg;
		}
		&::before,
		&::after {
			position: absolute;
			left: calc(50% - 2.4rem);
			content: "";
			width: 4.7rem;
			height: 0.3rem;
			background-color: var(--green);
			transform-origin: center center;
			transition: 500ms;
			border-radius: 1em;
		}
		&::before {
			transform: rotate(var(--slash-deg, -45deg));
		}
		&::after {
			transform: rotate(var(--slash-deg, 45deg));
		}
	}
	.prog {
		&-1 {
			display: none;
		}
		&-2 {
			display: none;
		}
		&-3 {
			display: none;
		}
		&-4 {
			display: none;
		}
		&-5 {
			display: none;
		}
		&-6 {
			display: none;
		}
	}
	.nav {
		&-1 {
			background-color: var(--pink);
		}
		&-2 {
			background-color: var(--burgundy);
		}
		&-3 {
			background-color: var(--charfreuse);
		}
		&-4 {
			background-color: var(--navy);
		}
		&-5 {
			background-color: var(--teal);
		}
		&-6 {
			background-color: var(--orange);
		}
	}
	.navs {
		width: 100%;
		padding: 0rem 14rem;
		display: none;
		flex-flow: column;
		justify-content: center;
		font-weight: bold;
		a.btn {
			position: absolute;
			right: 10rem;
			width: 10rem;
			border-radius: 100%;
			padding: 0.5rem;
			border: none;
			svg {
				width: 10rem;
				height: auto;
			}
			&:hover {
				background: transparent;
				transform: translateX(0.5rem);
			}
		}
		.inner-title {
			font-size: 4rem;
			padding: 1.8rem 0 0 0;
			max-width: 70%;
			font-weight: bold;
		}
		.nav-title {
			font-size: 2rem;
			text-transform: uppercase;
		}
		&.dark {
			color: var(--green);
			a,
			p,
			p a {
				color: var(--green);
			}
		}
		.light {
			color: var(--white);
		}
	}
	.row {
		&.main {
			height: 100%;
		}
		&.top {
			height: 72%;
		}
		&.nav {
			height: 28%;
			.col-md-12 {
				display: inline-flex;
			}
		}
	}
	.content {
		width: 100%;
		height: 100%;
		max-height: 100vh;
		.prog {
			&-1 {
				background-color: var(--pink);
			}
			&-2 {
				background-color: var(--burgundy);
			}
			&-3 {
				background-color: var(--charfreuse);
			}
			&-4 {
				background-color: var(--navy);
				ul {
					display: flex;
					flex-flow: column;
					li {
						display: flex;
						flex-flow: row;
						font-size: 2rem;
						line-height: 1.4;
						padding: 4rem 0 1rem 0;
						.inner-content {
							display: flex;
							flex-flow: column;
							justify-content: center;
						}
						strong {
							font-size: 2.4rem;
							display: block;
						}
					}
				}
				svg {
					width: 12rem;
				}
			}
			&-5 {
				background-color: var(--teal);
			}
			&-6 {
				background-color: var(--orange);
			}
		}
		.progs {
			min-height: 100%;
			padding: 9rem 10rem 4rem 10rem;
			max-height: 75vh;
			overflow-y: auto;
			.dark {
				color: var(--green);
				a,
				p,
				p a {
					color: var(--green);
				}
			}
			.light {
				color: var(--white);
			}
			&::-webkit-scrollbar-track {
				-webkit-box-shadow: none;
				background-color: rgba(0, 0, 0, 0.01);
			}
			&::-webkit-scrollbar {
				width: 1.5rem;
				background-color: rgba(0, 0, 0, 0.01);
			}
			&::-webkit-scrollbar-thumb {
				background-color: rgba(0, 0, 0, 0.1);
				border: none;
			}
			.section-title {
				font-size: 6.4rem;
				font-weight: bold;
				margin-bottom: 4rem;
			}
			.section-video {
				.video-wrapper {
					border-radius: 2rem;
					height: auto;
				}
			}
			p {
				font-size: 2rem;
				line-height: 1.6;
				margin-bottom: 4rem;
				strong {
					text-decoration: underline;
				}
				a {
					color: var(--white);
					text-decoration: underline;
					font-weight: bolder;
					&:hover {
						text-decoration: none;
					}
				}
			}
			ul {
				font-size: 2rem;
				line-height: 1.6;
				margin-bottom: 4rem;
				margin-top: -2rem;
				padding-left: 4rem;
				li {
					list-style: disc;
				}
			}
			h6 {
				font-size: 2rem;
				font-weight: bold;
				margin-bottom: 2rem;
				i {
					font-weight: normal;
				}
			}
		}
	}
	.picture {
		height: 72vh;
		img {
			width: 100%;
			display: block;
			object-fit: cover;
			height: 72vh !important;
		}
		.wrapper {
			width: 100%;
		}
	}
	@media screen and (orientation: portrait) {
		overflow-y: auto;
		overflow-x: hidden;
		&::-webkit-scrollbar-track {
			-webkit-box-shadow: none;
			background-color: rgba(0, 0, 0, 0.01);
		}
		&::-webkit-scrollbar {
			width: 1.5rem;
			background-color: rgba(0, 0, 0, 0.01);
		}
		&::-webkit-scrollbar-thumb {
			background-color: rgba(0, 0, 0, 0.1);
			border: none;
		}
		.container-fluid {
			height: auto;
		}
		.row.nav {
			.navs {
				padding: 2.8rem;
				.inner-title {
					max-width: 100%;
					font-size: 3.2rem;
				}
				a.btn {
					position: relative;
					left: 0;
					margin-top: 3rem;
					width: 6rem;
					svg {
						width: 6rem;
					}
				}
			}
		}
		.row.top {
			height: auto !important;
			flex-wrap: wrap;
		}
		.content {
			max-height: max-content;
			.progs {
				height: fit-content;
				min-height: 65vh;
				padding: 5rem 3rem 2rem 3rem;
				overflow: hidden;
				max-height: 500vh;
				p {
					font-size: 1.6rem;
				}
				.section-title {
					font-size: 3.2rem;
				}
			}
		}
		.picture {
			height: 30rem;
			img {
				height: 100% !important;
			}
		}
	}
}
