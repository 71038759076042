*,
*:before,
*:after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	overflow-x: hidden;
	overflow-y: auto;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: var(--black);
}

body {
	overflow: hidden;
	position: relative;
	font-family: var(--primary-font);
	font-style: normal;
	font-weight: normal;
	color: var(--black);
}

html,
body {
	//-webkit-transform: translateZ(0);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	//transform: translateZ(0);
}

a,
a:hover,
a:active,
a:focus {
	outline: none;
	text-decoration: none;
	-webkit-tap-highlight-color: transparent;
}

ul,
li {
	margin: 0;
	padding: 0;
	line-height: 1;
	list-style: none;
}

img,
svg {
	display: block;
	outline: none;
	max-width: inherit !important;
	max-height: inherit !important;
}

input,
input:focus,
select,
select:focus {
	font-style: normal;
	font-weight: normal;
	outline: none !important;
	box-shadow: none !important;
}

button,
button:focus {
	font-style: normal;
	font-weight: normal;
	outline: none !important;
	box-shadow: none !important;
	-webkit-tap-highlight-color: transparent;
}

html {
	font-size: 0.515vw;
}

main {
	// margin-top: var(--header-height);
}

@media screen and (orientation: portrait) {
	//no mobile
	// html {
	// 	display: none !important;
	// }
	@media screen and (min-width: 576px) {
		.container {
			max-width: 100% !important;
		}
		html {
			font-size: 1.5vw !important;
		}
	}
	@media screen and (max-width: 575px) {
		html {
			font-size: 2.5vw;
		}
	}
	.mobile {
		visibility: visible;
	}
	.desktop {
		display: none !important;
		visibility: hidden;
	}
}
/* For landscape, we want the tool bar stick on the left */

@media screen and (orientation: landscape) {
	.container {
		max-width: 90vw;
	}
	.mobile {
		display: none !important;
		visibility: hidden;
	}
	.desktop {
		visibility: visible;
	}
}

#toolbar {
	position: fixed;
	width: 2.65em;
	height: 100%;
}

@media (min-width: 1921px) {
	:root {
		--header-height: 129px !important;
	}
}

.text-uppercase {
	text-transform: uppercase;
}

[data-aos="reveal-right"] {
	transform: translateX(-100%);
	transform-origin: 100% 0%;
	transition-property: transform;
	transition-delay: 0.2s;
	transition-timing-function: ease-in-out;
}

[data-aos="reveal-right"].aos-animate {
	transform: translateX(0);
	transition-delay: 0s;
	transition-timing-function: ease-in-out;
}

a.btn {
	background-color: transparent;
	text-transform: uppercase;
	color: var(--white);
	padding: 0.2rem 2.8rem;
	border: 0.2rem solid var(--white);
	border-radius: 5rem;
	font-size: 1.6rem;
	font-weight: bold;
	margin-top: 0.2rem;
	transition: all 0.5s ease-in-out;
	&:hover {
		background-color: var(--white);
		color: var(--black);
		border: 0.2rem solid var(--white);
		transition: all 0.5s ease-in-out;
	}
}

body.has-modal-open {
	overflow: hidden;
	main#content,
	header,
	footer {
		opacity: 0;
		transition: all 0.5s ease-in-out;
	}
}

.position-relative {
	position: relative;
}

//Read More feature
.section-content {
	p {
		&.extra {
			display: none;
		}
		&.active {
			display: block !important;
		}
	}
	a.read-more {
		color: var(--green);
		font-size: 2rem;
		line-height: 1.4;
		margin: 2rem 0;
		position: relative;
		width: fit-content;
		display: flex;
		cursor: pointer;
		@media screen and (orientation: portrait) {
			font-size: 1.4rem;
		}
		&::before {
			display: none !important;
		}
		svg {
			width: 2rem;
			position: absolute;
			right: -2.5rem;
			top: 0.7rem;
			transition: all 0.3s ease-in-out;
		}
		&:hover {
			svg {
				top: 0.85rem;
				transition: all 0.3s ease-in-out;
			}
		}
		&.active {
			span.more {
				display: none;
			}
			span.hide {
				display: block;
			}
			svg {
				transform: rotate(180deg);
				transition: all 0.3s ease-in-out;
			}
			&:hover {
				svg {
					top: 0.55rem;
					transition: all 0.3s ease-in-out;
				}
			}
		}
		span.more {
			display: block;
		}
		span.hide {
			display: none;
		}
	}
}
