.contributors {
	position: relative;
	padding-top: 12rem;
	.section-title {
		margin-bottom: 6rem;
		text-align: left;
	}
	.swiper-nav-wrapper {
		display: none;
	}
	.section-content {
		padding-bottom: 8rem;
		position: relative;
		p {
			font-size: 2rem;
			line-height: 1.4;
			padding: 2rem 0;
		}
		padding-bottom: 8rem;
		.swiper-wrapper {
			flex-flow: wrap;
			justify-content: space-between;
		}
		.each-prog {
			&.prog {
				color: var(--white);
				overflow: hidden;
				position: relative;
				width: 32.5%;
				flex-shrink: unset;
				border-radius: 2rem;
				margin-bottom: 2.5rem;
				// cursor: pointer;
				overflow: hidden;
				&-1 {
					background-color: var(--pink);
					.content::before {
						background-color: var(--pink);
					}
				}
				&-2 {
					background-color: var(--burgundy);
					.content::before {
						background-color: var(--burgundy);
					}
				}
				&-3 {
					background-color: var(--charfreuse);
					.content::before {
						background-color: var(--charfreuse);
					}
				}
				&-4 {
					background-color: var(--navy);
					.content::before {
						background-color: var(--navy);
					}
				}
				&-5 {
					background-color: var(--teal);
					.content::before {
						background-color: var(--teal);
					}
				}
				&-6 {
					background-color: var(--orange);
					.content::before {
						background-color: var(--orange);
					}
				}
				&.dark {
					color: var(--green);
					a.btn {
						border-color: var(--green);
						color: var(--green);
					}
				}
				&.light {
					color: var(--white);
					p {
						color: var(--white);
					}
				}
				.prog-title {
				}
				p {
					font-size: 1.6rem;
				}
				.image {
					padding-bottom: 20rem;
				}
				img {
					width: 100%;
					height: auto;
					&.desktop {
						border-top-left-radius: 2rem;
						border-top-right-radius: 2rem;
					}
				}
				.headline {
					position: absolute;
					display: flex;
					height: 19rem;
					padding: 2rem;
					flex-flow: column;
					font-size: 2.6rem;
					font-weight: 600;
					line-height: 1.2;
					bottom: 0;
					opacity: 1;
					transition: all 0.5s ease-in-out;
				}
				a.btn {
					position: absolute;
					left: 2rem;
					bottom: 4rem;
					width: fit-content;
					z-index: 3;
					&:hover {
						color: var(--white);
						background-color: var(--green);
						border-color: var(--green);
					}
				}
				.content {
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					padding: 2rem 2rem 5rem 2rem;
					opacity: 0;
					// z-index: 2;
					display: flex;
					flex-flow: column;
					justify-content: center;
					transition: all 0.5s ease-in-out;
					&::before {
						content: "";
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						opacity: 1;
						border-radius: 2rem;
						// z-index: -1;
					}
					.title {
						font-size: 2.6rem;
						font-weight: 600;
						line-height: 1.2;
						z-index: 3;
						b {
							display: block;
						}
					}
					p {
						font-size: 1.7rem;
						z-index: 3;
					}
					strong {
						display: inline-block;
					}
				}
				&:hover {
					.content {
						opacity: 1;
						transition: all 0.5s ease-in-out;
						animation: fadeInLeft;
						animation-duration: 0.5s;
						strong {
							display: block;
							font-size: 2.8rem;
						}
					}
					.headline {
						// opacity: 0;
						transition: all 0.5s ease-in-out;
					}
					@media screen and (orientation: portrait) {
						.content {
							opacity: 1;
							animation: none;
							strong {
								display: inline-block;
								font-size: 1.4rem;
							}
						}
						.headline {
							opacity: 1;
						}
					}
				}
			}
			p {
				font-size: 2rem;
				padding: 2rem 0;
				line-height: 1.4;
				color: var(--green);
			}
		}
	}
	@media screen and (orientation: portrait) {
		padding-top: 7rem;
		padding-bottom: 2rem;
		.swiper {
			overflow: visible;
		}
		.section-header {
			border-bottom-right-radius: var(--corner-radius);
			border-bottom-left-radius: 0;
			.pic-caption {
				left: 0;
			}
			img {
				height: 40rem;
			}
		}
		.section-title {
			padding: 0;
			margin-bottom: 5rem;
			font-size: 4.4rem;
			br {
				display: none;
			}
		}
		.prog {
			.prog-title {
				margin: 4rem 3rem 3rem 3rem;
			}
			p {
				font-size: 1.4rem;
				margin: 0 3rem;
				line-height: 1.6;
			}
			&:hover {
				.content {
					display: none;
				}
			}
		}
		.section-content {
			padding-bottom: 3rem;
			.swiper-nav-wrapper {
				display: flex;
			}
			.swiper-wrapper {
				flex-flow: unset;
				.each-prog {
					flex-shrink: 0;
					.image {
						padding-bottom: 2rem;
					}
					.content {
						position: relative;
						opacity: 1;
						height: 29rem;
						justify-content: flex-start;
						.title {
							font-size: 2rem;
						}
						p {
							font-size: 1.4rem;
							margin: 0;
							padding-top: 2rem;
							padding-bottom: 0rem;
						}
					}
					a.btn {
						left: 2rem;
						bottom: 2rem;
					}
				}
			}
			p {
				font-size: 1.4rem;
			}
			img {
				width: 100%;
				height: calc(100vw - 6rem);
				object-fit: cover;
			}
			&:before {
				right: -6rem;
				top: -3rem;
			}
		}
	}
}
