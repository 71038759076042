.text-white {
  color: var(--white);
}

a {
  color: var(--black);
}

a:hover {
  color: var(--black);
}

strong.underline {
  text-decoration: underline;
}

.b-as-extra-bold {
  p {
    strong {
      font-weight: 800;
    }
  }
  strong {
    font-weight: 800;
  }
}

.b-as-bold {
  p {
    strong {
      font-weight: 700;
    }
  }
  strong {
    font-weight: 700;
  }
}

.h5 {
  font-size: 3.2rem;
  &strong {
    font-weight: 800;
  }
}

@media screen and (orientation: portrait) {
  :root {
    --bs-gutter-x: 3rem;
  }
  .fs-80 {
    font-size: 5rem;
  }
  .fs-72,
  .fs-72 p,
  .fs-48 {
    font-size: 4.8rem;
  }
  .fs-56 {
    font-size: 4rem;
  }
  .fs-24 {
    font-size: 1.8rem;
  }
  .fs-32,
  .fs-40 {
    font-size: 3.2rem;
  }
  .fs-20 {
    font-size: 1.4rem;
  }
}

@media screen and (orientation: landscape) {
  .pl-5 {
    padding-left: 5%;
  }
  .default p {
    font-size: 2rem;
    padding: 2rem 0;
    line-height: 1.4;
  }
  .fs-80,
  .fs-80 p {
    font-size: 8rem;
  }
  .fs-72,
  .fs-72 p {
    font-size: 7.2rem;
  }
  .fs-56 {
    font-size: 5.6rem;
  }
  .fs-48 {
    font-size: 4.8rem;
  }
  .fs-40 {
    font-size: 4rem;
  }
  .fs-32 {
    font-size: 3.2rem;
  }
  .fs-24 {
    font-size: 2.4rem;
  }
  .fs-20 {
    font-size: 2rem;
  }
  .fs-18 {
    font-size: 1.8rem;
  }
  p {
    font-size: 1.4rem;
    line-height: 1.4;
  }
}

.fw-bold {
  font-weight: bold;
}

.small-title {
  font-size: 2.4rem;
  text-transform: uppercase;
  font-weight: bold;
  color: var(--teal-accessible);
}
