:root {
    --progress-bar-height: 39rem;
}

#progress-bar {
    position: fixed;
    top: calc(50% - calc(var(--progress-bar-height)/2));
    right: 5.5vw;
    height: var(--progress-bar-height);
    width: 4.2rem;
    background: linear-gradient(180deg, rgba(245, 178, 173, 0.2) 0%, rgba(245, 178, 173, 0) 100%);
    border: 0.15rem solid #F2F2F2;
    border-radius: 5rem;
    z-index: 99;
    transition: all 0.5s ease-in-out;
    &:hover {
        background: linear-gradient(180deg, rgba(1, 131, 116, 0.1) 0%, rgba(1, 131, 116, 0.05) 100%);
        transition: all 0.5s ease-in-out;
    }
    ul {
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: space-around;
        align-items: center;
        padding: 1rem 0;
        li {
            position: relative;
            width: 1rem;
            height: 1rem;
            border-radius: 100%;
            background-color: var(--teal-accessible);
            transition: all 0.5s ease-in-out;
            &:hover {
                transition: all 0.5s ease-in-out;
                background-color: var(--teal);
                box-shadow: 0rem 0 0.25rem 0.005rem var(--teal);
            }
            a {
                background: transparent;
                display: block;
                height: 2.4rem;
                width: 2.4rem;
                border-radius: 100%;
                position: absolute;
                left: -0.7rem;
                top: -0.7rem;
                border: 0.1rem solid transparent;
                transition: all 0.5s ease-in-out;
                &:hover {
                    border: 0.1rem solid var(--teal);
                    transition: all 0.5s ease-in-out;
                }
            }
            &.active {
                a {
                    background-color: var(--teal);
                    transition: all 0.5s ease-in-out;
                    &::before {
                        transition: all 0.5s ease-in-out;
                        position: absolute;
                        content: '';
                        width: 1rem;
                        height: 1rem;
                        border-radius: 100%;
                        background-color: var(--white);
                        left: 0.63rem;
                        top: 0.63rem;
                    }
                }
            }
        }
    }
}
