section.process {
	background-color: var(--navy);
	padding: 7rem 0 12rem 0;
	border-bottom-left-radius: var(--corner-radius);
	border-bottom-right-radius: var(--corner-radius);
	position: relative;
	overflow: hidden;
	.main-swiper-outter {
		overflow: hidden;
		> .swiper-nav-wrapper {
			left: 0;
			bottom: 0;
			background: #17445d;
			padding: 0 26rem;
			position: absolute;
			justify-content: space-between;
			.swiper-button-disabled {
				opacity: 0.5;
			}
			.cs-swiper-pagination {
				font-size: 3.2rem;
				text-align: center;
				color: var(--white);
				font-weight: 600;
			}
		}
		.main-swiper {
			.swiper-slide {
				height: auto;
				&.first {
					.slide-wrapper {
						padding: 0 0.75rem;
					}
				}
				.slide-wrapper {
					max-width: 76%;
					margin: 0 auto;
				}
			}
			.container {
				padding: 0;
			}
			.section-title {
				.title {
					color: var(--charfreuse);
					font-size: 6.4rem;
					font-weight: 700;
					padding-top: 1rem;
				}
				.subtitle {
					color: var(--white);
					font-size: 3.2rem;
					font-weight: 600;
					margin: 4rem 0;
				}
			}
			.section-content {
				p {
					font-size: 1.8rem;
					padding: 0 2rem 3rem 0;
					line-height: 1.6;
					color: var(--white);
				}
				a {
					color: var(--white);
					text-decoration: underline;
				}
			}
			.each-step {
				padding: 0 2.5rem;
				.image {
					border-top-left-radius: 2rem;
					border-top-right-radius: 2rem;
					overflow: hidden;
					img {
						width: 100%;
					}
				}
				.headline {
					opacity: 0.5;
					text-transform: uppercase;
					font-size: 2.2rem;
					font-weight: 700;
				}
				.title {
					font-size: 3rem;
					font-weight: 700;
					padding: 1rem 0 0.5rem 0;
				}
				.content {
					background: var(--neutral-50);
					border-bottom-left-radius: 2rem;
					border-bottom-right-radius: 2rem;
					overflow: hidden;
					height: 32rem;
					padding: 1.5rem;
				}
				p {
					font-size: 1.5rem;
					padding-top: 1rem;
					line-height: 1.4;
					color: var(--green);
				}
				a {
					color: var(--green);
					text-decoration: underline;
				}
			}
			.last {
				.slide-wrapper {
					width: fit-content;
				}
				.swiper-slide {
					border-radius: 2rem;
					overflow: hidden;
				}
				.swiper-outter.top {
					width: 100rem;
					.swiper-button-prev:after,
					.swiper-button-next:after {
						color: var(--neutral-50);
					}
					.swiper-pagination-bullet {
						background-color: var(--neutral-50);
					}
					.swiper-pagination-bullet-active {
						background-color: var(--charfreuse);
					}
				}
				img {
					width: 100rem;
					height: auto;
				}
			}
		}
	}

	// [class*="col-"] {
	//     display: flex;
	//     justify-content: center;
	//     flex-flow: column;
	// }
	@media screen and (orientation: portrait) {
		.section-content {
			p {
				font-size: 1.5rem;
				padding: 1rem 0;
				line-height: 1.3;
			}
		}
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		position: relative;
		overflow: hidden;
		padding: 5rem 0;
		.main-swiper-outter {
			.main-swiper {
				&.swiper-horizontal .swiper-scrollbar {
					background: rgba(0, 0, 0, 0.25) !important;
					.swiper-scrollbar-drag {
						background: rgba(0, 0, 0, 0.75) !important;
					}
				}
				.swiper-slide {
					align-items: center;
					justify-content: center;
					display: flex;
					&.first {
						svg {
							margin-top: 1rem;
						}
						.slide-wrapper {
							padding: 0 0.75rem;
						}
					}
					.slide-wrapper {
						max-width: 100%;
						margin: 0 auto;
					}
				}
				.container {
					padding: 0;
				}
				.section-title {
					.title {
						font-size: 3.2rem;
						padding-top: 0;
					}
					.subtitle {
						font-size: 2.2rem;
						font-weight: 400;
						margin: 2.2rem 0;
					}
				}
				.section-content {
					p {
						font-size: 1.4rem;
						padding: 0 0 1.6rem 0;
						line-height: 1.6;
						color: var(--white);
					}
				}
				.each-step {
					padding: 0 0.75rem;
					.image {
						border-top-left-radius: 2rem;
						border-top-right-radius: 2rem;
						overflow: hidden;
						img {
							width: 100%;
						}
					}
					.headline {
						opacity: 0.5;
						text-transform: uppercase;
						font-size: 1.6rem;
						font-weight: 700;
					}
					.title {
						font-size: 2.4rem;
						font-weight: 700;
						padding: 1rem 0 0.5rem 0;
					}
					.content {
						background: var(--neutral-50);
						border-bottom-left-radius: 2rem;
						border-bottom-right-radius: 2rem;
						overflow: hidden;
						height: 46rem;
						padding: 1.5rem;
						margin-bottom: 1rem;
					}
					p {
						font-size: 1.4rem;
						padding-top: 1rem;
						line-height: 1.4;
						padding-bottom: 0;
					}
					a {
						color: var(--green);
						text-decoration: underline;
					}
				}
				.last {
					.slide-wrapper {
						width: fit-content;
					}
					.swiper-slide {
						border-radius: 2rem;
						overflow: hidden;
					}
					.swiper-outter.top {
						width: 100%;
						.swiper-button-prev:after,
						.swiper-button-next:after {
							color: var(--neutral-50);
						}
						.swiper-pagination-bullet {
							background-color: var(--neutral-50);
						}
						.swiper-pagination-bullet-active {
							background-color: var(--charfreuse);
						}
					}
					img {
						width: 100%;
						height: auto;
					}
				}
			}
		}
	}
}
