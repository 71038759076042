section.recommendations {
	background-color: var(--charfreuse);
	padding: 12rem 0 6rem;
	position: relative;
	border-top-right-radius: var(--corner-radius);
	.section-title {
		color: var(--green);
		font-size: 7.2rem;
		font-weight: 800;
	}
	.section-content {
		p {
			font-size: 1.8rem;
			padding: 3.6rem 0;
			line-height: 1.6;
			color: var(--green);
		}
		.row {
			padding-bottom: 4rem;
		}
	}
	.section-recommendations {
		padding-top: 10rem;
		position: relative;
		&:before {
			content: " ";
			position: absolute;
			height: 0.2rem;
			background-color: var(--white);
			top: 2rem;
			width: 82%;
			left: 9%;
		}

		.accordion-container {
			display: grid;
			grid-template-columns: auto auto;
			font-family: "Mulish" !important;
			.ac {
				background: transparent;
				border: none;
				border-radius: 2rem;
				padding: 0 0 7rem 1.5rem;
				width: 90%;
				line-height: 1.6;
				position: relative;

				p {
					font-size: 1.8rem;
					line-height: 1.6;
					margin-bottom: 2rem;
					&:last-child {
						margin-bottom: 0.5rem;
					}
				}
				ul {
					padding-left: 3rem;
					margin-bottom: 2rem;

					li {
						list-style: disc;
						font-size: 1.8rem;
						line-height: 1.6;
						margin-bottom: 1.6rem;
					}
				}
				.ac-header {
					.heading {
						display: flex;
						align-items: center;
						padding-bottom: 3rem;
						.text {
							font-family: var(--primary-font);
							font-size: 4rem;
							color: var(--green);
							padding: 0 0 0 2rem;
							font-weight: 400;
						}
						.sub {
							text-transform: uppercase;
							font-size: 1.6rem;
							font-weight: 800;
							padding-bottom: 0.5rem;
							padding-left: 0.2rem;
						}
					}
					img {
						width: 10rem;
						height: 10rem;
						padding: 0.5rem;
						border-radius: 100%;
						background-color: var(--green);
					}
				}
				.ac-trigger {
					padding: 0;
					cursor: default;
					&:after {
						display: none;
					}
				}
			}
		}
	}

	@media screen and (orientation: portrait) {
		border-top-right-radius: 5rem;
		padding: 5rem 0 0rem;
		&:before {
			left: 0;
			top: 0;
			width: 100%;
		}
		.section-title {
			padding-bottom: 4rem;
			font-size: 3.6rem;
		}
		.section-content {
			.row {
				padding-bottom: 0;
				border: none;
			}
			p {
				font-size: 1.4rem;
				padding: 1rem 0;
				line-height: 1.6;
			}
		}
		.section-recommendations {
			padding-top: 0;
			padding-bottom: 4rem;
			&:before {
				width: 100%;
				left: 0;
				top: 0;
				display: none;
			}
			.accordion-container {
				display: block;
				.ac {
					width: 100%;
					padding: 1.5rem;
					&:before {
						position: absolute;
						content: "";
						width: 100%;
						height: 0.2rem;
						background-color: var(--white);
						left: 0;
						top: -0.4rem;
					}
					&.next {
						transition: all 0.5s ease-in-out;
						&::before {
							height: 0;
						}
					}
					&.is-active {
						background: rgba(0, 0, 0, 0.1);
						&::before {
							height: 0;
						}
						.ac-trigger {
							&:after {
								transform: rotate(0deg) translate(1.5rem, -1rem);
								transition: transform 0.5s ease-in-out;
							}
						}
					}
					.ac-trigger {
						&:after {
							background-image: url(../images/arrow-down-rd.svg);
							content: " ";
							height: 2.4rem;
							transition: transform 0.5s ease-in-out;
							width: 2.4rem;
							display: block;
							transform: rotate(-180deg) translate(-1.5rem, 1rem);
						}
					}
					.ac-header {
						.heading {
							padding: 0.75rem 0;
							img {
								width: 6.5rem;
								height: 6.5rem;
							}
							.text {
								font-size: 2.2rem;
								padding-right: 1.5rem;
								.sub {
									font-size: 1.3rem;
								}
							}
						}
					}

					p,
					li,
					ul {
						font-size: 1.4rem !important;
					}
					p {
						margin: 1.5rem 0 0.5rem 0;
					}
				}
			}
		}
	}
}
